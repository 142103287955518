import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Projects = ({ location }) => {
  const projects = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 500) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              path
              title
              miniDescription
              images
            }
          }
        }
      }
    }
  `)

  const [fluidPointerPointer, setFluidPointer] = useState(null);
  const [clickedImgIndex, setClickedImgIndex] = useState(null)
  const imgRefs = useRef([useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]);

  useEffect(() => {
    console.log(imgRefs);
  }, [imgRefs])
  return (
    <Layout location={location}>
      <SEO title="Projects" />
      <div className="x-center" style={{marginTop: '50px', marginBottom: '60px'}}>
        <div className="container portfolio">
          <h2>Portfolio</h2>
          <div className="flex-row-wrap flush-margins">
            {projects.allMarkdownRemark.edges.map((edge, index) => {
              const fluidPointer = projects.allImageSharp.edges.filter(
                x =>
                  x.node.fluid.originalName ===
                  edge.node.frontmatter.images[0]
              )[0].node.fluid
              return (
                <div key={index} className="project-tile">
                  <Link to={edge.node.frontmatter.path}>
                  <div className="thumbnail">
                    <Img
                      fluid={fluidPointer}
                      className="image-mover"
                      ref={imgRefs.current[index]}
                    />
                    <strong>{edge.node.frontmatter.title}</strong>
                  </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Projects